import axios from 'axios';
import {VUE_APP_TERM_DOCGEN_URL} from '@/config';
import * as events from '@/store/term-docgen/docgen/event-types';

export const actions = {
	async TERM_DOCGEN_KAZFINDER({commit}, {link}) {
		let rBody = {
			link: link
		};
		return axios.post(VUE_APP_TERM_DOCGEN_URL + '/process', rBody).then((resp) => {
			commit(events.DOCGEN_SET_DATA_TO_STATE, resp.data);
			return resp.data;
		});
	},

	async TERM_DOCGEN_GENERATE({commit}, {
		address,
		repr,
		full_name,
		dog_num,
		city,
		formatted_date,
		org,
		pay_made,
		back,
		refund,
		retention_med,
		retention_law,
		retention_spec,
		refund_med,
		refund_law,
		refund_spec,
		nds,
		nds2,
		bik,
		rs,
		bank,
		passport_num,
		passport_data,
		client_name,
		client_lastname,
		client_middle_name,
		bank_contract,
		credit_date_create,
		termination_date,
		conclusion_date,
		full_months,
		period_cost_law,
		period_cost_med,
		pay_spec,
		pay_software_unlimited,
		pay_software_urgent,
		amount_retention_spec,
		amount_retention_unlimited,
		amount_retention_urgent,
		amount_refund_unlimited,
		amount_refund_urgent
	}) {
		let rBody = {
			formatted_date: formatted_date,
			dog_num: dog_num,
			city: city,
			org: org,
			full_name: full_name,
			pay_made: Number(pay_made),
			refund: Number(refund),
			back: back,
			address: address,
			retention_med: Number(retention_med),
			retention_law: Number(retention_law),
			retention_spec: Number(retention_spec),
			refund_med: Number(refund_med),
			refund_law: Number(refund_law),
			refund_spec: Number(refund_spec),
			nds: Number(nds),
			nds_2: Number(nds2),
			bik: bik,
			rs: rs,
			bank: bank,
			client_name: client_name,
			client_last_name: client_lastname,
			client_middle_name: client_middle_name,
			bank_contract: bank_contract,
			credit_date_create: credit_date_create,
			passport_num: passport_num,
			passport_data: passport_data,
			repr: repr,
			termination_date: termination_date,
			conclusion_date: conclusion_date,
			full_months: Number(full_months),
			period_cost_law: Number(period_cost_law),
			period_cost_med: Number(period_cost_med),
			pay_spec: Number(pay_spec),
			pay_software_unlimited: Number(pay_software_unlimited),
			pay_software_urgent: Number(pay_software_urgent),
			amount_retention_spec: Number(amount_retention_spec),
			amount_retention_unlimited: Number(amount_retention_unlimited),
			amount_retention_urgent: Number(amount_retention_urgent),
			amount_refund_unlimited: Number(amount_refund_unlimited),
			amount_refund_urgent: Number(amount_refund_urgent)
		};
		for (let key in rBody) {
			if (rBody[key] === '' || rBody[key] === null) {
				delete rBody[key];
			}
		}
		return axios.post(VUE_APP_TERM_DOCGEN_URL + '/docgen', rBody, {responseType: 'blob'}).then((resp) => {
			const blob = new Blob([resp.data], {type: resp.headers['content-type']});
			let filename = '';
			let disposition = resp.headers['content-disposition'];

			let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
			let matches = filenameRegex.exec(disposition);
			if (matches != null && matches[1]) {
				filename = decodeURI(matches[1].slice(7));
			}
			let a = document.createElement('a');
			a.href = URL.createObjectURL(blob);
			a.download = filename;
			a.click();
		});
	}
};
